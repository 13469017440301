/*
 * @Author: 诸葛青
 * @Date: 2023-09-06 19:21:21
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-08 21:22:30
 */
import vote from '@/views/vote/Index.vue'
import voteSetting from '@/views/vote-setting/Index.vue'

export default [
  {
    path: '/vote',
    name: 'vote',
    component: vote
  },
  {
    path: '/vote-setting',
    name: 'voteSetting',
    component: voteSetting
  },
]