/*
 * @Author: 诸葛青
 * @Date: 2023-08-02 16:57:13
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-09 14:14:33
 */
import axios from "axios";
import { Message } from "element-ui";

const request = axios.create({
    withCredentials: true,
    baseURL: 'https://backend-voter.asf.ink',
    // baseURL: '',
    timeout: 120000,
})

// * request拦截器

request.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error)
});

const errorCode = {
    401: '认证失败，无法访问系统资源',
    403: '当前操作没有权限',
    404: '访问资源不存在',
    default: '系统未知错误，请反馈给管理员'
}

request.interceptors.response.use((res) => {
    console.log(res);
    const code = res.data.code;

    if (res.data.code !== 0) {

        const msg = errorCode[code] || errorCode['default']

        Message({
            message: msg,
            type: 'warning'
        })
    }
    return Promise.resolve(res.data);
}, error => {
    const status = error.response.status
    const msg = errorCode[status] || errorCode['default']
    Message({
        message: msg,
        type: 'warning'
    })
    return Promise.reject(error);
})

export default request;
