/*
 * @Author: 诸葛青
 * @Date: 2023-09-08 22:06:26
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-09 11:13:53
 */
import request from '@/utils/axios';


export function saveVoteSetting(params) {
    return request.post('/api/voting-machine/new', params);
}
