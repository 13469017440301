/*
 * @Author: 诸葛青
 * @Date: 2023-09-07 16:08:42
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-09 15:39:28
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import _ from 'lodash' //导入loadsh插件

import ws from "./utils/ws";//找到封装的socket.js文件
Vue.prototype.$ws = ws;//将其挂在原型上，这样 $socketApi就在所有的 Vue 实例中可用了。
Vue.prototype._ = _;//将其挂在原型上，这样 $socketApi就在所有的 Vue 实例中可用了。


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
