<!--
 * @Author: 诸葛青
 * @Date: 2023-09-07 16:08:42
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-11 19:31:45
-->
<!--
 * @Author: 诸葛青
 * @Date: 2023-09-07 16:08:42
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-08 21:28:43
-->
<template>
  <div>
    <el-header v-if="!isOutSide">
      <div class="header" @click="goHome">VTB工具库</div>
    </el-header>
    <el-main v-if="!isOutSide">
      <router-view />
    </el-main>
    <router-view v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      whiteList: ["/vote"],
      isOutSide: true,
    };
  },
  watch: {
    "$route.fullPath": {
      handler(val) {
        this.isOutSide = this.whiteList.includes(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    goHome() {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
.header {
  color: #409eff;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddfe6;
  cursor: pointer;
}
</style>
