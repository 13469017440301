<!--
 * @Author: 诸葛青
 * @Date: 2023-09-07 16:08:56
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-08 21:24:54
-->
<!--
 * @Author: 诸葛青
 * @Date: 2023-09-06 19:12:35
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-08 18:15:20
-->
<template>
  <!-- <div class="home">
  </div> -->
  <div>
    <el-button @click="goToRoute('vote')" type="primary">投票</el-button>
    <el-button @click="goToRoute('vote-setting')" type="primary">投票设置</el-button>
    <el-button>主播</el-button>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    goToRoute(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
</style>
