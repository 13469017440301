/*
 * @Author: 诸葛青
 * @Date: 2023-09-06 19:12:35
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-07 16:21:09
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import voteRouter from './vote/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  ...voteRouter
]

const router = new VueRouter({
  routes
})

export default router
