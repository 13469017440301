<!--
 * @Author: 诸葛青
 * @Date: 2023-09-08 14:18:08
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-11 19:34:32
-->
<template>
  <div>
    <el-card>
      <el-form :inline="true">
        <el-form-item label="倒计时（单位：秒）">
          <el-input-number v-model="form.second"></el-input-number>
        </el-form-item>
        <el-form-item label="选项数目（范围：1-5）">
          <el-input-number
            :min="1"
            :max="5"
            v-model="form.number"
            @change="changeNumber"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <el-form>
        <div class="member-box">
          <el-form-item
            class="member-flex"
            :label="`参赛选手名${index + 1}`"
            v-for="(item, index) in form.member"
            :key="index"
          >
            <el-input clear="member-input" v-model="form.member[index]"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
    <el-card style="margin-top: 10px; text-align: right">
      <el-button @click="submit" type="primary" :loading="btnLoading">提交</el-button>
    </el-card>
  </div>
</template>

<script>
import { saveVoteSetting } from "@/api/vote.js";
export default {
  data() {
    return {
      form: {
        second: 60,
        number: 2,
        member: ["", ""],
      },
      btnLoading: false,
    };
  },
  methods: {
    changeNumber() {
      if (this.form.number > this.form.member.length) {
        this.form.member.push("");
      } else {
        this.form.member.splice(this.form.member.length - 1, 1);
      }
    },
    submit() {
      this.btnLoading = true;
      const keyWords = [];
      for (let i = 0; i < this.form.member.length; i++) {
        keyWords.push({
          title: this.form.member[i],
          key_word: this.form.member[i],
          weight: i + 1,
        });
      }
      saveVoteSetting({
        live_room_id: "30323927",
        voting_machine: {
          duration_of_voting: this.form.second,
          voting_key_words: keyWords,
        },
      })
        .then((item) => {
          console.log(item);
          this.$message({
            message: "提交成功",
            type: "success",
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.member-box {
  display: flex;
  flex-wrap: wrap;
}
.member-flex {
  width: 200px;
  padding-right: 10px;
}
</style>
