<!--
 * @Author: 诸葛青
 * @Date: 2023-09-06 19:22:35
 * @LastEditors: 诸葛青
 * @LastEditTime: 2023-09-11 19:38:13
-->
<template>
  <div class="main-box" ref="vote">
    <div class="countdown">倒计时：{{ countdown }}秒</div>
    <div class="container">
      <div class="vote-box">
        <div
          class="vote-member"
          :style="{
            width: 100 / list.length + '%',
          }"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="member-info" :style="memberInfoStyle">
            <el-image class="img" :src="item.icon || require('@/assets/test.png')">
            </el-image>
            <!-- <img :src="" class="img" /> -->
            <div>{{ item.title }}</div>
            <div>票数：{{ item.current_vote_count }}</div>
            <el-progress
              :style="progressStyle"
              :percentage="calculatePercent(item.current_vote_count)"
              :stroke-width="10"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0,
      countdown: 60,
      totalVotes: 0,
      list: [],
      progressStyle: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "30px",
        paddingRight: "30px",
      },
      memberFontSize: "18px",
      memberInfoStyle: {
        lineHeight: "30px",
        fontSize: "18px",
      },
    };
  },
  mounted() {
    this.setStyle();
    window.addEventListener("resize", () => {
      this.setStyle();
    });

    this.$ws.initWebSocket(this.getsocketResult);
  },
  beforeDestroy() {
    this.$ws.closeWebSocket();
  },
  methods: {
    setStyle() {
      const progressPadding = (this.$refs["vote"].clientWidth / 1920) * 30;
      this.progressStyle.paddingLeft = progressPadding + "px";
      this.progressStyle.paddingRight = progressPadding + "px";

      this.memberInfoStyle.fontSize = (this.$refs["vote"].clientWidth / 1920) * 18 + "px";
      this.memberInfoStyle.lineHeight =
        (progressPadding < 20 ? 20 : progressPadding) + "px";
    },

    // socket信息返回接受函数
    getsocketResult(data) {
      console.log("我拿到信息了", data);
      const info = this.isJSON2(data) ? JSON.parse(data) : data;
      console.log(info);
      const cmd = info.cmd || "VOTING_MACHINE_CURRENT_DATA";
      const payload = info.payload || {
        duration_of_voting: 60,
        total_votes: 10,
        voting_key_words: [
          {
            title: "5",
            key_word: "5",
            weight: 2,
            current_vote_count: 2,
          },
          {
            title: "4",
            key_word: "4",
            weight: 1,
            current_vote_count: 3,
          },
          {
            title: "3",
            key_word: "3",
            weight: 3,
            current_vote_count: 1,
          },
          {
            title: "2",
            key_word: "2",
            weight: 4,
            current_vote_count: 2,
          },
          {
            title: "1",
            key_word: "1",
            weight: 5,
            current_vote_count: 2,
          },
        ],
      };
      if (cmd === "VOTING_MACHINE_CURRENT_DATA") {
        this.list = this._.orderBy(payload.voting_key_words, ["weight"]);
        this.countdown = payload.duration_of_voting;
        this.totalVotes = payload.total_votes;
      }
    },
    //发送socket信息
    websocketSend(data) {
      this.$socketApi.sendSock(data);
    },
    calculatePercent(val) {
      return this.totalVotes > 0 ? Math.floor(val / this.totalVotes * 100) : 0;
    },
    isJSON2(str) {
      let result = false;
      try {
        JSON.parse(str);
        result = true;
      } catch (e) {
        result = false;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.main-box {
  /* width: 100%; */
  height: 330px;
  width: calc(100vw);
}
.countdown {
  margin: 0 auto;
  color: #3293d3;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}
.vote-box {
  background: rgba(76, 77, 86, 0.5);
  border: 10px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  color: #fff;
  padding: 0 0 10px 0;
  flex-wrap: wrap;
  max-width: 1920px;
  max-height: 1080px;
  min-width: 150px;
}
.vote-member {
  min-width: 100px;
}
.member-info {
  max-width: 200px;
  margin: 0 auto;
}
.img {
  width: 50%;
  max-height: 100%;
  min-height: 100%;
}
</style>
